import React, { createContext, useContext, useState } from "react";

const QueueContext = createContext();

export function QueueProvider({ children }) {
    const [queue, setQueue] = useState([]);

    const addPatient = (ssn) => {
        const newPatient = {
            id: queue.length + 1, // 대기번호
            ssn,
            estimatedTime: (queue.length + 1) * 10, // 예상 대기 시간
        };
        setQueue((prevQueue) => [...prevQueue, newPatient]);
        return newPatient.id; // 대기번호 반환
    };

    return (
        <QueueContext.Provider value={{ queue, addPatient }}>
            {children}
        </QueueContext.Provider>
    );
}

// useQueue 훅 정의
export function useQueue() {
    const context = useContext(QueueContext);
    if (!context) {
        throw new Error("useQueue must be used within a QueueProvider");
    }
    return context;
}
